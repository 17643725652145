import React, { useState } from 'react';
import { Container, Badge, Row, Col, Button, Input, Label, Jumbotron } from "reactstrap";
import axios from '../../utils/AxiosInstance';
import { Link } from 'react-router-dom'
import crypto from 'crypto'
import StepWizard from 'react-step-wizard';
import { PayPalButton } from "react-paypal-button-v2";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import StepCounter from '../../layout/StepCounter'
import classes from './Upload.module.scss';
import { useTranslation } from 'react-i18next';
/* eslint react/prop-types: 0 */

/**
 * A basic demonstration of how to use the step wizard
 */
const Upload = () => {
    //const { t } = useTranslation();
    const [activenode, updateNode] = useState(1);
    const [state, updateState] = useState({
        form: { hash: '', email: '', filename: '' },
        transitions: {
            enterRight: `${classes.animated} ${classes.enterRight}`,
            enterLeft: `${classes.animated} ${classes.enterLeft}`,
            exitRight: `${classes.animated} ${classes.exitRight}`,
            exitLeft: `${classes.animated} ${classes.exitLeft}`,
            intro: `${classes.animated} ${classes.intro}`,
        },
        upload: {
            documentId: '',
            loading: false
        }
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    // Do something on step change
    const onStepChange = (stats) => {
        // console.log(stats);
    };

    const setInstance = () => updateState({
        ...state
    });

    return (
        <div>
            <section className={classes.maincontainer}>
                <Container className={classes.page}>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div className={classes.stepsSVG}>
                                <StepCounter nodes={3} active={activenode} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }} sm="12">
                            <Jumbotron fluid>
                                <Row className={classes.innerJumbotron}>
                                    <Col md={{ size: 8, offset: 2 }} sm="12">
                                        <StepWizard
                                            onStepChange={onStepChange}
                                            isHashEnabled
                                            transitions={state.transitions} // Comment out for default transitions
                                            instance={setInstance}
                                        >
                                            <First update={updateForm} updatenode={updateNode} state={state} />
                                            <Second update={updateState} form={state.form} updatenode={updateNode} state={state} />
                                            <Last state={state} updatenode={updateNode} />
                                        </StepWizard>
                                    </Col>
                                </Row>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Upload;

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
const Stats = ({
    nextStep,
    previousStep,
    totalSteps,
    step,
    firstStep,
    updatenode
}) => {
    const { t } = useTranslation();
    const firstStepOverride = () => {
        updatenode(1);
        firstStep();
    }
    const nextStepOverride = () => {
        updatenode(step + 1);
        nextStep();
    }
    const previousStepOverride = () => {
        updatenode(step - 1);
        previousStep();
    }
    return (
        <div className={classes.stats}>
            {step === 2 &&
                <Button className={classes.backBtn} color="link" block={true} onClick={previousStepOverride}>{t('Indietro')}</Button>
            }
            {step < totalSteps && step !== 2 &&
                <Button className={classes.nextBtn} color="link" block={true} onClick={nextStepOverride}>{t('Continua')}</Button>
            }
            {step === totalSteps &&
                <Button className={classes.nextBtn} color="link" block={true} onClick={firstStepOverride}>{t('Nuovo Caricamento')}</Button>
            }
        </div>
    )
};

/** Steps */

const First = props => {
    const { t } = useTranslation();
    const [validemail, updateValidEmail] = useState(true);
    const [validfile, updateValidFile] = useState(true);
    const re = /\S+@\S+\.\S+/;
    const validate = () => {

        if (re.test(props.state.form.email) && props.state.form.hash !== '') {
            updateValidFile(true);
            updateValidEmail(true);
            props.nextStep();
        } else {
            if (!re.test(props.state.form.email))
                updateValidEmail(false);
            if (props.state.form.hash === '')
                updateValidFile(false);
            props.updatenode(1);
        }
    };
    const errormessage = t('campo non valido');
    const update = (e) => {
        props.update(e.target.name, e.target.value);
        if (e.target.name === 'filename' && e.target.name !== '') {
            const reader = new FileReader()
            reader.onload = async (evt) => {
                console.log(evt.target.result);
                const hash = crypto.createHash('sha256');
                let bytes = new Uint8Array(evt.target.result)
                hash.update(bytes);
                //console.log(hash.digest('hex'));
                props.update('hash', hash.digest('hex'));
            };
            try {
                reader.readAsArrayBuffer(e.target.files[0]);
            } catch { };
        }
    };

    return (
        <div>
            <h3 className={classes.titleBox}>{t('Carica documento')}</h3>
            <p className={classes.subtitleBox}>{t('Puoi caricare un solo file alla volta, di qualunque formato tranne archivi compressi come .zip o .rar')}</p>
            <div className={classes.iconfirst}>
                <img className={classes.iconfirstsize} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Come+funziona/Cards/Group+2/icon_loadDoc.svg" alt="icon" />
            </div>
            <Label>{t('Il tuo documento')}</Label><Label className={classes.validationerror}>{validfile ? '' : errormessage}</Label>
            <div className='custom-file mb-4'>
                <Input
                    type='file'
                    className='custom-file-input'
                    name='filename'
                    id='filename'
                    onChange={update}
                />
                <Label className='custom-file-label' htmlFor='filename'>
                    {props.state.form.filename.split('\\').pop()}
                </Label>
            </div>

            <Label>{t('Il tuo indirizzo e-mail')}</Label><Label className={classes.validationerror}>{validemail ? '' : errormessage}</Label>
            <Input type='text' className='form-control' id='email' name='email' placeholder={t('es. annalisa@gmail.com')}
                onChange={update} />

            <p className={classes.pieform}>{t('Proseguendo acconsenti ai')}
                <Link className={classes.pieformBtn} to="/termsandcond"> {t("Termini e condizioni d'uso")} </Link>
                {t('e alla normativa sulla')}<Link className={classes.pieformBtn} to="/privacy"> {t("Privacy")} </Link></p>
            <Stats step={1} {...props} nextStep={validate} />
        </div>
    );
};


const Second = props => {
    const { t } = useTranslation();
    const styleOpt = {
        shape: 'pill',
        size: 'responsive'
    }
    return (
        <div>
            <LoadingMask loading={props.state.upload.loading} loadingText={t("Attendi...")}>
                <h3 className={classes.titleBox}>{t('Modalità di pagamento')}</h3>
                <p className={classes.subtitleBox}>{t('Costo totale')}: 2€ {t('(iva inclusa)')}</p>
                <PayPalButton
                    amount="2.00"
                    style={styleOpt}
                    shippingPreference="NO_SHIPPING" // Default is "GET_FROM_FILE"
                    onSuccess={async (details, data) => {
                        console.log(details);
                        console.log(data);
                        console.log("Transaction completed by " + details.payer.name.given_name);

                        // OPTIONAL: Call your server to save the transaction
                        const { upload } = props.state;
                        upload['documentId'] = '';
                        upload['loading'] = true;
                        props.update({
                            ...props.state,
                            upload,
                        });
                        let response = await axios('/upload', {
                            method: 'POST',
                            data: {
                                "hash": props.state.form.hash,
                                "email": props.state.form.email,
                                "transactionId": details.id,
                                "transactionBody": details,
                                "filename": props.state.form.filename.split('\\').pop()
                            }
                        });
                        if (response.data.statusCode === 200) {
                            console.log('Data uploaded');
                            const { upload } = props.state;
                            upload['documentId'] = response.data.documentId;
                            upload['loading'] = false;
                            props.update({
                                ...props.state,
                                upload,
                            });
                            //Go to next step
                            props.nextStep();
                        } else {
                            alert(t('Qualcosa è andato storto, contatta l assistenza') + " support@primopositum.com");
                            const { upload } = props.state;
                            upload['documentId'] = '';
                            upload['loading'] = false;
                            props.update({
                                ...props.state,
                                upload,
                            });
                        }
                    }}
                    options={{
                        clientId: process.env.REACT_APP_PAYPAL_CLIENTID,
                        currency: "EUR"
                    }}
                />
                <Stats step={2} {...props} />
            </LoadingMask>
        </div>
    );
};

const Last = (props) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className={'text-center'}>
                <h3 className={classes.titleBox}>{t('Caricamento completato')}</h3>
                <div className={classes.iconthird}>
                    <img className={classes.iconthirdsize} src="https://primopositum.s3.eu-central-1.amazonaws.com/Carica/inblockchain.svg" alt="icon" />
                </div>
                <p className={classes.subtitleBox}>{t('Abbiamo generato il tuo codice univoco, usalo per identificare il tuo documento ogni volta che ne avrai bisogno')}</p>
                <Badge className={classes.documentbadge} pill>{props.state.upload.documentId}</Badge>
                <br /><br />
                <p className={classes.subtitleBox}>{t('riceverai il codice anche via e-mail')}</p>
            </div>
            <Stats step={3} {...props} />
        </div>
    );
};
