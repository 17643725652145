import React from 'react';
import { Button, Container, Col, Row } from "reactstrap";
import { Link } from 'react-router-dom'
import classes from './Home.module.scss';
import { useTranslation } from 'react-i18next';


const Home = () => {
    const { t } = useTranslation();
    return (
        <div>
            <section id="hero" className={classes.hero}>
                <Container>
                    <Row className={classes.heroFirstRow}>
                        <Col md={{ size: 10, offset: 1 }}>
                            <h3 className={classes.head}><span className={classes.violet}>{t('Certifica')}</span> {t('i tuoi documenti')}</h3>
                            <h3 className={classes.head}>{t('con la tecnologia')}<span className={classes.violet}> blockchain</span></h3>
                        </Col>
                    </Row>
                    <Row className={classes.heroSecondRow}>
                        <Col md={{ size: 2, offset: 1 }}>
                            <Button className={classes.buttonLink} color="link"
                                tag={Link} to="/upload">{t('Provalo Subito')}</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={classes.banner} id="banner">
                <Container>
                    <Row>
                        <Col md="12">
                            <h4 className={classes.bannerTitle}><span className={classes.violet}>PrimoPositum</span> {t('è il primo servizio')} {t('globale')} {t('per la')}
                                <br />{t('certificazione sicura tramite')} <span className={classes.violet}>blockchain</span></h4>
                            <div className={classes.bannerTxt}>{t('La tecnologia piu avanzata del momento che garantisce la massima affidabilità ad aziende, professionisti e privati')}</div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={classes.howitworks} id="howitworks">
                <Container>
                    <Row className={classes.howitworksFirstRow}>
                        <Col md="12" className={classes.howitworkstitle}>
                            <h4 className={classes.head4}>{t('Come funziona')}</h4>
                        </Col>
                    </Row>
                    <Row className={classes.howitworksSecondRow}>
                        <Col md="4">
                            <div className={classes.box}>
                                <h5 className={classes.boxtitle}>
                                    <span className={classes.boxbulletpoint}>1. </span>
                                    {t('Carica un documento')}
                                </h5>
                                <p className={classes.boxtext}>{t('Puoi certificare qualunque tipo di file')}</p>
                                <img className={classes.boximage} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Come+funziona/Cards/Group+2/icon_loadDoc.svg" alt="1" width="177px" height="148px" />
                            </div>
                        </Col>
                        <Col md="4">
                            <div className={classes.box}>
                                <h5 className={classes.boxtitle}>
                                    <span className={classes.boxbulletpoint}>2. </span>
                                    {t('Ricevi la tua chiave')}
                                </h5>
                                <p className={classes.boxtext}>{t('Genera un codice univoco per ogni documento caricato')}</p>
                                <img className={classes.boximage} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Come+funziona/Cards/Group+2-1/icon_safe.svg" alt="1" width="177px" height="148px" />
                            </div>
                        </Col>
                        <Col md="4">
                            <div className={classes.box}>
                                <h5 className={classes.boxtitle}>
                                    <span className={classes.boxbulletpoint}>3. </span>
                                    {t('Verifica quando vuoi')}
                                </h5>
                                <p className={classes.boxtext}>{t('Usa il codice per verificare in ogni momento')}</p>
                                <img className={classes.boximage} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Come+funziona/Cards/Group+2-2/icon_docVerified.svg" alt="1" width="177px" height="148px" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="securityintro" className={classes.securityintro}>
                <Container>
                    <Row className={classes.securityintroRow}>
                        <Col md="12" lg={{ size: 10, offset: 1 }}>
                            <h4 className={classes.head4}>PrimoPositum {t('è lo strumento ideale che semplifica la vita di privati, professionisti e aziende')}</h4>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="security" className={classes.security}>
                <Container>
                    <Row className={classes.securityRow}>
                        <Col xl="4" className={classes.boxsec}>
                            <div>
                                <h4 className={classes.head4}><span className={classes.lightblue}>{t('Privati')}</span></h4>
                                <img className={classes.boxsecimage} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Privati+Prof+Aziende/Privati/private.jpg" alt="person" />
                                <p className={classes.securitytext}>{t('descrizione privati')}</p>
                            </div>
                        </Col>
                        <Col xl="4" className={classes.boxsec}>
                            <div>
                                <h4 className={classes.head4}><span className={classes.lightblue}>{t('Professionisti')}</span></h4>
                                <img className={classes.boxsecimage} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Privati+Prof+Aziende/Professionisti/freelance.jpg" alt="worker" />
                                <p className={classes.securitytext}>{t('descrizione professionisti')}</p>
                            </div>
                        </Col>
                        <Col xl="4" className={classes.boxsec}>
                            <div>
                                <h4 className={classes.head4}><span className={classes.lightblue}>{t('Aziende')}</span></h4>
                                <img className={classes.boxsecimage} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Privati+Prof+Aziende/Aziende/enterprise.jpg" alt="company" />
                                <p className={classes.securitytext}>{t('descrizione aziende')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="price" className={classes.price}>
                <Container>
                    <Row className={classes.priceFirstRow}>
                        <Col md="12" className="text-center">
                            <h4 className={classes.head4}>{t('Quanto costa')} PrimoPositum?</h4>
                        </Col>
                    </Row>
                    <Row className={classes.priceSecondRow}>
                        <Col md="12" className="text-center">
                            <h4 className={classes.head4}>{t('Fino al 31 Dicembre 2021 il costo del servizio')}<br />
                                {t('è di soli')}<span className={classes.pink}> 2€ </span>{t('a documento')}<span className={classes.violet}>*</span></h4>
                        </Col>
                    </Row>
                    <Row className={classes.priceThirdRow}>
                        <Col md="6" xl={{ size: 5, offset: 1 }} className={classes.pricemainboxsx}>
                            <div className={classes.whitebackground}>
                                <div className={classes.paymethodhead}>{t('Metodi di pagamento accettati')}</div>
                                <div><img className={classes.ccimage} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Costo+e+pagamenti/Payments+block/payment_methods.png" alt="cc" /></div>
                            </div>
                        </Col>
                        <Col md="6" xl="5" className={classes.pricemainboxdx}>
                            <div className={classes.whitebackground}>
                                <img className={classes.priceimg} src="https://primopositum.s3.eu-central-1.amazonaws.com/Home/Costo+e+pagamenti/Payments+block/cc.png" alt="2" />
                            </div>
                        </Col>
                    </Row>
                    <Row className={classes.priceForthRow}>
                        <Col md="12" className="text-center">
                            <p className={classes.pricenote}>*{t('prezzo valido fino al 31 Dicembre 2021')}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Home;