import React, { useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import classes from './Info.module.scss';
import { useTranslation } from 'react-i18next';


const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation();
    return (
        <div className={classes.maincontainer}>
            <Container className={classes.page}>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <h3 className={classes.head}>{t('Privacy')}</h3>
                        <h4 className={classes.subtitle}>{t('Trattamento dei dati personali')}</h4>
                        <h5 className={classes.sectiontitle}>{t('Finalità del trattamento cui sono destinati i dati')}</h5>
                        <p className={classes.paragraph}>{t('privacy1')}</p>
                        <p className={classes.paragraph}>{t('privacy2')}</p>
                        <p className={classes.paragraph}>{t('privacy3')}</p>
                        <ol>
                            <li><p className={classes.paragraph}>{t('privacy4')}</p></li>
                            <li><p className={classes.paragraph}>{t('privacy5')}</p></li>
                        </ol>
                        <p className={classes.paragraph}>{t('privacy6')}</p>

                        <h5 className={classes.sectiontitle}>{t('Modalità di trattamento dei dati')}</h5>
                        <p className={classes.paragraph}>{t('privacy11')}</p>
                        <p className={classes.paragraph}>{t('privacy7')}</p>
                        <p className={classes.paragraph}>{t('privacy8')}</p>

                        <h5 className={classes.sectiontitle}>{t('Cookie')}</h5>
                        <p className={classes.paragraph}>{t('privacy9')}</p>

                        <h4 className={classes.subtitle}>{t('Richiesta di informazioni riguardanti il servizio')}</h4>
                        <h5 className={classes.sectiontitle}>{t('Modalità di contatto')}</h5>
                        <p className={classes.paragraph}>{t('privacy10')}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default Privacy;