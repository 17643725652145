import React from 'react';
import {
    Button,
    Container,
    Row,
    Col
} from 'reactstrap';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import classes from './Layout.module.scss';


const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className={classes.footer}>
            <Container>
                <Row>
                    <Col md="12" lg="6">
                        <span className={classes.copyright}>&copy;2021 PrimoPositum. {t('Tutti i diritti riservati.')}</span>&nbsp;<span className={classes.piva}>{t('P.IVA')} 04526200169</span>
                    </Col>
                    <Col md="12" lg="6" className={classes.footermenu}>
                        <Button className={classes.footerBtn} color="link" tag={Link} to="/aboutus">{t('Chi siamo')}</Button>
                        <Button className={classes.footerBtn} color="link" tag={Link} to="/faq">{t('FAQ')}</Button>
                        <Button className={classes.footerBtn} color="link" tag={Link} to="/termsandcond">{t("Termini e condizioni d'uso")}</Button>
                        <Button className={classes.footerBtn} color="link" tag={Link} to="/privacy">{t('Privacy')}</Button>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}


export default Footer;