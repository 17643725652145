import React, { useState } from 'react';
import {
  Button,
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import classes from './Layout.module.scss';
import Logo from '../../logo.svg';


const NavbarMenu = () => {
  const { t } = useTranslation();
  const [state, updateState] = useState({ isOpen: false });

  const location = useLocation();

  const toggle = () => {
    updateState({ isOpen: !state.isOpen });
  };

  return (
    <div >
      <Navbar className="navbar-purple" expand="md">
        <Container>
          <NavbarBrand className="navbar-brand" tag={Link} to="/"><img src={Logo} alt="PrimoPositum" width="161px" /></NavbarBrand>
          <NavbarToggler className="navbar-toggle" onClick={toggle} />
          <Collapse isOpen={state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {location.pathname === '/' ?
                <NavItem><NavLink className={classes.navbarLi} href="/#howitworks">{t('Come funziona')}</NavLink></NavItem> : null}
              {location.pathname === '/' ?
                <NavItem><NavLink className={classes.navbarLi} href="/#security">{t('Sicurezza')}</NavLink></NavItem> : null}
              {location.pathname === '/' ?
                <NavItem><NavLink className={classes.navbarLi} href="/#price">{t('Costi')}</NavLink></NavItem> : null}
            </Nav>
            <Button color="link" size="sm"><NavLink className={classes.verifyBtn} tag={Link} to="/verify">{t('Verifica')}</NavLink></Button>
            <Button color="link" size="sm"><NavLink className={classes.uploadBtn} tag={Link} to="/upload">{t('Carica')}</NavLink></Button>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
}


export default NavbarMenu;
