import React, { useState } from 'react';
import { Container, Row, Col, Badge, Button, Input, Label, Jumbotron } from "reactstrap";
import axios from '../../utils/AxiosInstance';
import crypto from 'crypto'
import StepWizard from 'react-step-wizard';
import classes from './Verify.module.scss';
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import StepCounter from '../../layout/StepCounter'
import { useTranslation } from 'react-i18next';

const Verify = () => {
    const { t } = useTranslation();
    const [activenode, updateNode] = useState(1);
    const [state, updateState] = useState({
        form: { hash: '', documentId: '', filename: '' },
        transitions: {
            enterRight: `${classes.animated} ${classes.enterRight}`,
            enterLeft: `${classes.animated} ${classes.enterLeft}`,
            exitRight: `${classes.animated} ${classes.exitRight}`,
            exitLeft: `${classes.animated} ${classes.exitLeft}`,
            intro: `${classes.animated} ${classes.intro}`,
        },
        verification: {
            result: false,
            message: '',
            loading: false
        }
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    // Do something on step change
    const onStepChange = async (stats) => {
        const { verification } = state;
        verification['message'] = '';
        verification['result'] = '';
        verification['loading'] = true;
        updateState({
            ...state,
            verification,
        });
        console.log(stats);
        if (stats.activeStep === 2) {
            axios('/verify', {
                method: 'POST',
                data: {
                    "hash": state.form.hash,
                    "documentId": state.form.documentId
                }
            }).then((response) => {
                if (response.data.statusCode === 200) {
                    console.log('succeded');
                    //update information to display
                    const { verification } = state;
                    verification['result'] = response.data.result;
                    verification['message'] = response.data.message;
                    verification['loading'] = false;
                    updateState({
                        ...state,
                        verification,
                    });
                } else {
                    const { verification } = state;
                    verification['message'] = '';
                    verification['result'] = false;
                    verification['loading'] = true;
                    updateState({
                        ...state,
                        verification,
                    });
                    alert(t('Errore, riprova'))
                }
            }).catch(error => {
                console.log(error);
            });
        }
    };

    const setInstance = () => updateState({
        ...state
    });

    return (
        <div>
            <section className={classes.maincontainer}>
                <Container className={classes.page}>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div className={classes.stepsSVG}>
                                <StepCounter nodes={2} active={activenode} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }} sm="12">
                            <Jumbotron fluid>
                                <Row className={classes.innerJumbotron}>
                                    <Col md={{ size: 8, offset: 2 }} sm="12">
                                        <StepWizard
                                            onStepChange={onStepChange}
                                            isHashEnabled
                                            transitions={state.transitions} // comment out for default transitions
                                            instance={setInstance}
                                        >
                                            <First update={updateForm} state={state} updatenode={updateNode} />
                                            <Last state={state} updatenode={updateNode} />
                                        </StepWizard>
                                    </Col>
                                </Row>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Verify;

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
const Stats = ({
    nextStep,
    totalSteps,
    step,
    firstStep,
    result,
    updatenode
}) => {
    const { t } = useTranslation();
    const firstStepOverride = () => {
        updatenode(1);
        firstStep();
    }
    const nextStepOverride = () => {
        updatenode(step + 1);
        nextStep();
    }
    return (
        <div>
            {step < totalSteps ?
                <Button className={classes.nextBtn} color="link" block={true} onClick={nextStepOverride}>{t('Continua')}</Button>
                :
                <Button className={classes.nextBtn} block={true} color={result ? 'link' : 'link'}
                    onClick={firstStepOverride}>{t('Nuova Verifica')}</Button>
            }
        </div>
    )
};

/** Steps */

const First = props => {
    const { t } = useTranslation();
    const [validcode, updateValidCode] = useState(true);
    const [validfile, updateValidFile] = useState(true);
    const validate = () => {
        if (props.state.form.documentId !== '' && props.state.form.hash !== '') {
            updateValidFile(true);
            updateValidCode(true);
            props.nextStep();
        } else {
            if (props.state.form.email !== '')
                updateValidCode(false);
            if (props.state.form.hash === '')
                updateValidFile(false);
            props.updatenode(1);
        }
    };
    const errormessage = t('campo non valido');
    const update = (e) => {
        props.update(e.target.name, e.target.value);
        if (e.target.name === 'documentId' && e.target.value !== '') {
            props.update('documentId', e.target.value.replace(/\s/g, ''));
        }

        if (e.target.name === 'filename' && e.target.value !== '') {
            const reader = new FileReader()
            reader.onload = async (evt) => {
                console.log(evt.target.result);
                const hash = crypto.createHash('sha256');
                let bytes = new Uint8Array(evt.target.result)
                hash.update(bytes);
                //console.log(hash.digest('hex'));
                props.update('hash', hash.digest('hex'));
            };
            try {
                reader.readAsArrayBuffer(e.target.files[0]);
            } catch { };
        }
    };
    return (
        <div>
            <h3 className={classes.titleBox}>{t('Verifica documento')}</h3>
            <p className={classes.subtitleBox}>{t('Carica il documento che vuoi verificare')}</p>
            <div className={classes.icon}>
                <img className={classes.iconsize} src="https://primopositum.s3.eu-central-1.amazonaws.com/Verifica/icon_verifyUpload.svg" alt="icon" />
            </div>
            <Label>{t('Il tuo documento')}</Label><Label className={classes.validationerror}>{validfile ? '' : errormessage}</Label>
            <div className='custom-file mb-4'>
                <Input
                    type='file'
                    className='custom-file-input'
                    name='filename'
                    onChange={update}
                />
                <Label className='custom-file-label' htmlFor='filename'>
                    {props.state.form.filename.split('\\').pop()}
                </Label>
            </div>
            <Label>{t('ID Documento')}</Label>
            <Label className={classes.validationerror}>{validcode ? '' : errormessage}</Label>
            <Input type='text' className='form-control' name='documentId' placeholder={t('es. 47fe234ab78fd22')}
                onChange={update} maxLength={22} />
            <br />
            <Stats step={1} {...props} nextStep={validate} result={false} />
        </div>
    );
};


const Last = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <LoadingMask loading={props.state.verification.loading} loadingText={t('Attendi...')}>
                <div className={'text-center'}>
                    <h3 className={classes.titleBox}>{t('Verifica documento')}</h3>
                    <p className={classes.subtitleBox}>{t('Esito')}&nbsp;&nbsp;&nbsp;{props.state.verification.result ?
                        <Badge className={classes.resultsuccess} color="success" pill>{t('POSITIVO')}</Badge> :
                        <Badge className={classes.resultfail} color="danger" pill>{t('NEGATIVO')}</Badge>}
                    </p>
                    <div className={classes.icon}>
                        {props.state.verification.result ?
                            <img className={classes.iconsize} src="https://primopositum.s3.eu-central-1.amazonaws.com/Verifica/icon_verifySuccess.svg" alt="icon" /> :
                            <img className={classes.iconsize} src="https://primopositum.s3.eu-central-1.amazonaws.com/Verifica/icon_verifyError.svg" alt="icon" />
                        }
                    </div>
                    <p className={classes.subtitleBox}>{t('ID Documento')}</p>
                    {props.state.verification.result ?
                        <Badge className={classes.documentbadgesuccess} pill>{props.state.form.documentId}</Badge> :
                        <Badge className={classes.documentbadgefail} pill>{props.state.form.documentId}</Badge>
                    }
                    <br /><br />
                    {props.state.verification.result ?
                        <p className={classes.pieform}>{t('Il file caricato risulta presente in blockchain dal')}
                            <br /><span className={classes.date}>{new Date(props.state.verification.message).toISOString().replace(/.\d+Z$/g, "").replace("T", ", ") + ' UTC'}</span></p> :
                        <p className={classes.pieformfail}>{t('Il file caricato non corrisponde.')} </p>
                    }
                    {props.state.verification.result ? <br /> :
                        <div>
                            <div className={classes.separator}></div>
                            <p className={classes.negativenote}>{t('Recupera la versione originale del file e assicurati di usare il codice univoco corretto')}</p>
                        </div>
                    }
                </div>
                <Stats step={2} {...props} result={props.state.verification.result} />
            </LoadingMask>
        </div>
    );
};
