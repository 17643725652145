import React, { useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import LinkedIn from '../../../images/linkedin.svg';
import Enrico from '../../../images/Enrico.png';
import Andrea from '../../../images/Andrea.png';
import Erminia from '../../../images/Erminia.png';
import classes from './Info.module.scss';
import { useTranslation } from 'react-i18next';


const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation();
    return (
        <div className={classes.maincontainer}>
            <Container className={classes.page}>
                <Row>
                    <Col md="12" lg={{ size: 10, offset: 1 }}>
                        <h3 className={classes.head}>{t('Chi siamo')}</h3>
                        <h4 className={classes.subtitle}>{t('Il primo servizio per certificare documenti con la blockchain')}</h4>
                        <h5 className={classes.sectiontitle}>{t('Con le persone prima di tutto')}</h5>
                        <p className={classes.paragraph}>{t('about1')}<br />{t('about2')}</p>
                        <p className={classes.paragraph}>{t('about7')}</p>
                    </Col>
                </Row>
                <Row className={classes.member}>
                    <Col md="5" lg={{ size: 4, offset: 1 }} xl={{ size: 3, offset: 1 }}>
                        <div className={classes.userprofile}>
                            <img src={Enrico} alt="Enrico Espinosa" width="253px" height="253px" />
                        </div>
                    </Col>
                    <Col md="7" lg="6" xl="7">
                        <h4 className={classes.subtitle}>{t('Enrico Espinosa')}</h4>
                        <h5 className={classes.sectiontitle}>{t('CEO')}
                            <span>
                                <a href="https://www.linkedin.com/in/enricoespinosa/" target="_blank" rel="noopener noreferrer">
                                    &nbsp;<img src={LinkedIn} alt="linkedin" />
                                </a>
                            </span>
                        </h5>
                        <p className={classes.paragraph}>{t('about3')}</p>
                    </Col>
                </Row>
                <Row className={classes.member}>
                    <Col md="5" lg={{ size: 4, offset: 1 }} xl={{ size: 3, offset: 1 }}>
                        <div className={classes.userprofile}>
                            <img src={Andrea} alt="Andrea Piccinini" width="253px" height="253px" />
                        </div>
                    </Col>
                    <Col md="7" lg="6" xl="7">
                        <h4 className={classes.subtitle}>Andrea Piccinini</h4>
                        <h5 className={classes.sectiontitle}>{t('CTO')}
                            <span>
                                <a href="https://www.linkedin.com/in/andrea-piccinini/" target="_blank" rel="noopener noreferrer">
                                    &nbsp;<img src={LinkedIn} alt="linkedin" />
                                </a>
                            </span>
                        </h5>
                        <p className={classes.paragraph}>{t('about4')}</p>
                    </Col>
                </Row>
                {/*
                <Row className={classes.member}>
                    <Col md="5" lg={{ size: 4, offset: 1 }} xl={{ size: 3, offset: 1 }}>
                        <div className={classes.userprofile}>
                            <img src={Erminia} alt="Erminia Rubino" width="253px" height="253px" />
                        </div>
                    </Col>
                    <Col md="7" lg="6" xl="7">
                        <h4 className={classes.subtitle}>Erminia Rubino</h4>
                        <h5 className={classes.sectiontitle}>{t('Marketing Manager')}
                            <span>
                                <a href="https://www.linkedin.com/in/erminia-rubino-987452180/" target="_blank" rel="noopener noreferrer">
                                    &nbsp;<img src={LinkedIn} alt="linkedin" />
                                </a>
                            </span>
                        </h5>
                        <p className={classes.paragraph}>{t('about5')}</p>
                    </Col>
                </Row>
                */}
            </Container>
        </div>
    );
};


export default AboutUs;
