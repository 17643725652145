import React from 'react';
//import classes from './Layout.module.scss';


const StepCounter = props => {
    var circles = () => {
        let nodes = [];
        let x = 0;
        for (let i = 0; i < props.nodes; i++) {
            if (i + 1 === props.active) {
                nodes.push(ActiveCircle({ 'value': i + 1, 'cx': x }));
                x = x + 40;
            }
            else {
                nodes.push(Circle({ 'value': i + 1, 'cx': x }));
                x = x + 40;
            }
            if (i + 1 !== props.nodes) {
                nodes.unshift(Line({ 'x': x }));
                x = x + 19;
            }
        }
        return nodes;
    }
    return (
        <svg width={props.nodes * 40 + (props.nodes - 1) * 19} height="40">
            {circles()}
        </svg>
    );
};

const Circle = props => {
    return (
        <g key={props.cx}>
            <circle cx={props.cx + 20} cy="20" r="20" stroke="#6BB7C9" strokeWidth="0" fill="#6BB7C9" />
            <text x={props.cx + 20} y="50%" textAnchor="middle" stroke="#72EFDD" fill="#72EFDD" strokeWidth="1px" dy=".3em" fontSize="24px">{props.value}</text>
        </g>
    );
};

const ActiveCircle = props => {
    return (
        <g key={props.cx}>
            <circle cx={props.cx + 20} cy="20" r="20" stroke="#80FFDB" strokeWidth="0" fill="#80FFDB" />
            <text x={props.cx + 20} y="50%" textAnchor="middle" stroke="#F72585" fill="#F72585" strokeWidth="1px" dy=".3em" fontSize="24px">{props.value}</text>
        </g>
    );
};

const Line = props => {
    return (
        <g key={props.x - 1}>
            <line x1={props.x - 20} y1="20" x2={props.x + 39} y2="20" stroke="#6BB7C9" strokeWidth="40" fill="#6BB7C9" />
        </g>
    );
};


export default StepCounter;