import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';

// The translations
const resources = {
  en: {
    translation: translationEN
  },
  it: {
    translation: translationIT
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false, // We do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

console.log('detected language: ', i18n.language);
if (!i18n.language.includes('it') && !i18n.language.includes('en')) {
  i18n.changeLanguage('en', (err, t) => {
    if (err) return console.log('something went wrong loading ', err);
  });
}
console.log('rendered language: ', i18n.language);

export default i18n;