import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Upload from './components/pages/Upload/Upload';
import Verify from './components/pages/Verify/Verify';
import Home from './components/pages/Home/Home';
import TermsAndCond from './components/pages/Info/TermsAndCond'
import FAQ from './components/pages/Info/FAQ'
import AboutUs from './components/pages/Info/AboutUs'
import Privacy from './components/pages/Info/Privacy'
import NavbarMenu from './components/layout/NavbarMenu';
import Footer from './components/layout/Footer';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
//import classes from './App.module.scss';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA);

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname, window.location.search);
  });

  return (
    <div>
      <Router>
        <NavbarMenu />
        <Route exact path="/upload" render={props => (
          <Upload />
        )} />
        <Route exact path="/verify" render={props => (
          <Verify />
        )} />
        <Route exact path="/" render={props => (
          <Home />
        )} />
        <Route exact path="/termsandcond" render={props => (
          <TermsAndCond />
        )} />
        <Route exact path="/faq" render={props => (
          <FAQ />
        )} />
        <Route exact path="/aboutus" render={props => (
          <AboutUs />
        )} />
        <Route exact path="/privacy" render={props => (
          <Privacy />
        )} />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText={t("Accetta")}
          style={{ background: "#F72585 " }}
          buttonStyle={{ color: "#ffffff", backgroundColor: "#3A0CA3", fontSize: "13px" }}
        >
          {t('Questo sito usa cookies per migliorare la tua esperienza di navigazione.')}&nbsp;<Link style={{ color: "#3A0CA3" }} to="/privacy"> {t("Leggi l'informativa")} </Link>
        </CookieConsent>
      </Router>
    </div >
  );
}

export default App;
