import React, { useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import classes from './Info.module.scss';
import { useTranslation } from 'react-i18next';


const TermsAndCond = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation();
    return (
        <div className={classes.maincontainer}>
            <Container className={classes.page}>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <h3 className={classes.head}>{t('Termini e condizioni d\'uso')}</h3>
                        <br />
                        <p className={classes.paragraph}>{t('terms1')}</p>
                        <h5 className={classes.sectiontitle}>{t('Accesso al sito')}</h5>
                        <p className={classes.paragraph}>{t('terms2')}</p>
                        <h5 className={classes.sectiontitle}>{t('Diritto di privacy e copyright')}</h5>
                        <p className={classes.paragraph}>{t('terms3')}</p>
                        <h5 className={classes.sectiontitle}>{t('Informazioni dell\'utente')}</h5>
                        <p className={classes.paragraph}>{t('terms4')}</p>
                        <h5 className={classes.sectiontitle}>{t('Garanzie')}</h5>
                        <p className={classes.paragraph}>{t('terms5')}</p>
                        <h5 className={classes.sectiontitle}>{t('Link e contenuti di terze parti')}</h5>
                        <p className={classes.paragraph}>{t('terms6')}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default TermsAndCond;