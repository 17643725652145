import React, { useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import classes from './Info.module.scss';
import { useTranslation } from 'react-i18next';


const FAQ = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation();
    return (
        <div className={classes.maincontainer}>
            <Container className={classes.page}>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <h3 className={classes.head}>{t('Frequently Asked Questions')}</h3>
                        <h4 className={classes.subtitle}>{t('Servizio e assistenza')}</h4>
                        <h5 className={classes.sectiontitle}>{t('Dove vengono archiviati i file?')}</h5>
                        <p className={classes.paragraph}>{t('faq1')}</p>
                        <h5 className={classes.sectiontitle}>{t('Per quanto tempo viene mantenuto il mio documento?')}</h5>
                        <p className={classes.paragraph}>{t('faq2')}</p>
                        <h5 className={classes.sectiontitle}>{t('Quali dati archivia PrimoPositum?')}</h5>
                        <p className={classes.paragraph}>{t('faq3')}</p>
                        <h5 className={classes.sectiontitle}>{t('Come vengono conservati i codici identificativi dei file?')}</h5>
                        <p className={classes.paragraph}>{t('faq4')}</p>
                        <h5 className={classes.sectiontitle}>{t('Cosa è l\'ID documento?')}</h5>
                        <p className={classes.paragraph}>{t('faq5')}</p>
                        <h5 className={classes.sectiontitle}>{t('Cosa succede se perdo l\'ID documento?')}</h5>
                        <p className={classes.paragraph}>{t('faq6')}</p>
                        <h5 className={classes.sectiontitle}>{t('Posso rimuovere un documento dall\'archivio di PrimoPositum?')}</h5>
                        <p className={classes.paragraph}>{t('faq7')}</p>
                        <h5 className={classes.sectiontitle}>{t('Garanzie e limitazioni di responsabilità sull\'erogazione del servizio?')}</h5>
                        <p className={classes.paragraph}>{t('faq8')}</p>
                        <h5 className={classes.sectiontitle}>{t('Sono una azienda o un libero professionista, devo pagare ad ogni singolo documento che carico?')}</h5>
                        <p className={classes.paragraph}>{t('faq9')}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default FAQ;